import { Box, Paper, Button, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CallIcon from '@mui/icons-material/Call';
import LanguageIcon from '@mui/icons-material/Language';
import SendIcon from '@mui/icons-material/Send';
import dayjs from 'dayjs';
import { useReactToPrint } from 'react-to-print';
import { Print } from '@mui/icons-material';
import Logo from "../../assets/images/logo.png";
import { LogoLarge } from '../global/sidebar/logo';

const Visiting = ({ visitors }) => {

    const [data, setData] = useState({ _10: [], _12: [], _02: [], _04: [] });

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    useEffect(() => {
        var _10 = visitors.filter((fitem) => fitem.time_slot == '10:00 AM');
        var _12 = visitors.filter((fitem) => fitem.time_slot == '12:00 PM');
        var _02 = visitors.filter((fitem) => fitem.time_slot == '02:00 PM');
        var _04 = visitors.filter((fitem) => fitem.time_slot == '04:00 PM');

        setData({ _10, _12, _02, _04 })
    }, []);

    return (
        <>
            <Box sx={{ textAlign: "center" }}>
                <Button onClick={handlePrint} startIcon={<Print />}>Print</Button>
            </Box>
            <Box ref={componentRef} sx={{ padding: '20px 30px', minHeight: "100vh", display: 'flex', justifyContent: 'space-between', flexDirection: 'column', }}>
                <Box>
                    <Typography variant='h5' sx={{ textAlign: 'center' }}>Today's Visitors</Typography>
                    <hr style={{ border: '1px solid black', marginTop: '5px' }}></hr>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: "center", marginTop: '10px' }}>
                        <Box sx={{ textAlign: "center" }}>
                            <LogoLarge />
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography variant='h6' sx={{ textAlign: 'right' }}>Date: <span>{dayjs().format('DD/MM/YYYY')}</span></Typography>
                        </Box>
                    </Box>
                    <Box>
                        {data._10.length != 0 &&
                            <>
                                <Box>
                                    <Typography variant='h5' sx={{ textAlign: 'center', margin: "10px 0px" }}>Time Slot : 10:00 AM || Person : {data._10.length}</Typography>
                                </Box>
                                <TableContainer component={Box}>
                                    <Table sx={{ border: "solid 1px #cfcfcf" }} size='small'>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ fontWeight: "600" }}>S.No</TableCell>
                                                <TableCell sx={{ fontWeight: "600" }}>Associate Name</TableCell>
                                                <TableCell sx={{ fontWeight: "600" }} align='center'>Mobile No</TableCell>
                                                <TableCell sx={{ fontWeight: "600" }} align='center'>Customer Name</TableCell>
                                                <TableCell sx={{ fontWeight: "600" }} align='center'>Visit Date</TableCell>
                                                <TableCell sx={{ fontWeight: "600" }} align="right">Time Slot</TableCell>
                                                <TableCell sx={{ fontWeight: "600" }} align="right">Person</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {data._10.map((item, index) => (
                                                <TableRow>
                                                    <TableCell>{index + 1}.</TableCell>
                                                    <TableCell>{item?.first_name + " " + item?.last_name}</TableCell>
                                                    <TableCell align='center'>{item?.mobile_no}</TableCell>
                                                    <TableCell align='center' sx={{ textTransform: "capitalize" }}>{item?.full_name}</TableCell>
                                                    <TableCell align='center'>{dayjs(item?.visit_date).format('DD-MMM-YYYY')}</TableCell>
                                                    <TableCell align="right">{item?.time_slot}</TableCell>
                                                    <TableCell align="right">{item?.no_of_per}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </>
                        }
                        {data._12.length != 0 &&
                            <>
                                <Box>
                                    <Typography variant='h5' sx={{ textAlign: 'center', margin: "10px 0px" }}>Time Slot : 12:00 PM || Person : {data._12.length}</Typography>
                                </Box>
                                <TableContainer component={Box}>
                                    <Table sx={{ border: "solid 1px #cfcfcf" }} size='small'>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ fontWeight: "600" }}>S.No</TableCell>
                                                <TableCell sx={{ fontWeight: "600" }}>Associate Name</TableCell>
                                                <TableCell sx={{ fontWeight: "600" }} align='center'>Mobile No</TableCell>
                                                <TableCell sx={{ fontWeight: "600" }} align='center'>Customer Name</TableCell>
                                                <TableCell sx={{ fontWeight: "600" }} align='center'>Visit Date</TableCell>
                                                <TableCell sx={{ fontWeight: "600" }} align="right">Time Slot</TableCell>
                                                <TableCell sx={{ fontWeight: "600" }} align="right">Person</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {data._12.map((item, index) => (
                                                <TableRow>
                                                    <TableCell>{index + 1}.</TableCell>
                                                    <TableCell>{item?.first_name + " " + item?.last_name}</TableCell>
                                                    <TableCell align='center'>{item?.mobile_no}</TableCell>
                                                    <TableCell align='center' sx={{ textTransform: "capitalize" }}>{item?.full_name}</TableCell>
                                                    <TableCell align='center'>{dayjs(item?.visit_date).format('DD-MMM-YYYY')}</TableCell>
                                                    <TableCell align="right">{item?.time_slot}</TableCell>
                                                    <TableCell align="right">{item?.no_of_per}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </>
                        }
                        {data._02.length != 0 &&
                            <>
                                <Box>
                                    <Typography variant='h5' sx={{ textAlign: 'center', margin: "10px 0px" }}>Time Slot : 02:00 PM || Person : {data._02.length}</Typography>
                                </Box>
                                <TableContainer component={Box}>
                                    <Table sx={{ border: "solid 1px #cfcfcf" }} size='small'>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ fontWeight: "600" }}>S.No</TableCell>
                                                <TableCell sx={{ fontWeight: "600" }}>Associate Name</TableCell>
                                                <TableCell sx={{ fontWeight: "600" }} align='center'>Mobile No</TableCell>
                                                <TableCell sx={{ fontWeight: "600" }} align='center'>Customer Name</TableCell>
                                                <TableCell sx={{ fontWeight: "600" }} align='center'>Visit Date</TableCell>
                                                <TableCell sx={{ fontWeight: "600" }} align="right">Time Slot</TableCell>
                                                <TableCell sx={{ fontWeight: "600" }} align="right">Person</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {data._02.map((item, index) => (
                                                <TableRow>
                                                    <TableCell>{index + 1}.</TableCell>
                                                    <TableCell>{item?.first_name + " " + item?.last_name}</TableCell>
                                                    <TableCell align='center'>{item?.mobile_no}</TableCell>
                                                    <TableCell align='center' sx={{ textTransform: "capitalize" }}>{item?.full_name}</TableCell>
                                                    <TableCell align='center'>{dayjs(item?.visit_date).format('DD-MMM-YYYY')}</TableCell>
                                                    <TableCell align="right">{item?.time_slot}</TableCell>
                                                    <TableCell align="right">{item?.no_of_per}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </>
                        }
                        {data._04.length != 0 &&
                            <>
                                <Box>
                                    <Typography variant='h5' sx={{ textAlign: 'center', margin: "10px 0px" }}>Time Slot : 04:00 PM || Person : {data._04.length}</Typography>
                                </Box>
                                <TableContainer component={Box}>
                                    <Table sx={{ border: "solid 1px #cfcfcf" }} size='small'>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ fontWeight: "600" }}>S.No</TableCell>
                                                <TableCell sx={{ fontWeight: "600" }}>Associate Name</TableCell>
                                                <TableCell sx={{ fontWeight: "600" }} align='center'>Mobile No</TableCell>
                                                <TableCell sx={{ fontWeight: "600" }} align='center'>Customer Name</TableCell>
                                                <TableCell sx={{ fontWeight: "600" }} align='center'>Visit Date</TableCell>
                                                <TableCell sx={{ fontWeight: "600" }} align="right">Time Slot</TableCell>
                                                <TableCell sx={{ fontWeight: "600" }} align="right">Person</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {data._04.map((item, index) => (
                                                <TableRow>
                                                    <TableCell>{index + 1}.</TableCell>
                                                    <TableCell>{item?.first_name + " " + item?.last_name}</TableCell>
                                                    <TableCell align='center'>{item?.mobile_no}</TableCell>
                                                    <TableCell align='center' sx={{ textTransform: "capitalize" }}>{item?.full_name}</TableCell>
                                                    <TableCell align='center'>{dayjs(item?.visit_date).format('DD-MMM-YYYY')}</TableCell>
                                                    <TableCell align="right">{item?.time_slot}</TableCell>
                                                    <TableCell align="right">{item?.no_of_per}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </>
                        }
                    </Box>
                </Box>
                <Box>
                    <Box sx={{ display: "flex", justifyContent: "flex-end", gap: "10px", marginTop: '20px' }}>
                        <Box sx={{ flex: .3, textAlign: "center" }}>
                            <hr></hr>
                            <Typography>Authorised Signature</Typography>
                        </Box>
                    </Box>
                    {/* <hr style={{ border: '1px solid black', margin: "5px 0px" }}></hr>
                    <Box sx={{ textAlign: "center" }}>
                        <Typography variant='p'>Address: Plot No A-09, 4th Floor, Above TilakRam Restaurent, J.K Tower, Shubh Business Zone, In Front of - People's Hospital Campus Bhanpur, Bhopal - 462037</Typography>
                    </Box> */}
                </Box>
            </Box>

        </>
    )
}



export default Visiting